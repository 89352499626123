var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Judul",
      "label-for": "Judul"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "Judul"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Pilih Paket"
    }
  }, [_vm._v("Pilih Kategori Paket")]), _c('small', {
    staticClass: "text-danger"
  }, [_c('b', [_vm._v(" (*)")])]), _c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optpaket_id,
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    model: {
      value: _vm.form.category_paket_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "category_paket_id", $$v);
      },
      expression: "form.category_paket_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Mata Pelajaran",
      "label-for": "mapel_id"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optmapel_id,
      "reduce": function reduce(option) {
        return option.value;
      }
    },
    model: {
      value: _vm.form.mapel_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mapel_id", $$v);
      },
      expression: "form.mapel_id"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu (Detik)",
      "label-for": "timer_second"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "timer_second"
    },
    model: {
      value: _vm.form.timer_second,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "timer_second", $$v);
      },
      expression: "form.timer_second"
    }
  })], 1)], 1), _vm.type == 'pauli' ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tambah Opsi Soal",
      "label-for": "opsi"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.formopsi,
      callback: function callback($$v) {
        _vm.formopsi = $$v;
      },
      expression: "formopsi"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "--",
      "label-for": "opsi"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addOpsi();
      }
    }
  }, [_vm._v("Tambah Opsi")])], 1)], 1)], 1)], 1), _vm.type != 'pauli' ? _c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Pertanyaan")])];
      },
      proxy: true
    }], null, false, 1644594461)
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
    attrs: {
      "id": "Konten",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Pembahasan")])];
      },
      proxy: true
    }], null, false, 1343525816)
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
    attrs: {
      "id": "Pembahasan",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.form.pembahasan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pembahasan", $$v);
      },
      expression: "form.pembahasan"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('hr'), _vm.type == 'pg' || _vm.type == 'multiple_choice' || _vm.type == 'benar_salah' ? _c('div', [_c('b-tabs', {
    staticClass: "mt-2"
  }, _vm._l(_vm.form.soals, function (data, index) {
    return _c('div', {
      key: index
    }, [_c('b-tab', {
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm.type != 'pauli' ? _c('div', [index + 1 == 1 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. E")]) : _vm._e()]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
      attrs: {
        "id": "Konten",
        "options": _vm.editor_options
      },
      model: {
        value: data.content,
        callback: function callback($$v) {
          _vm.$set(data, "content", $$v);
        },
        expression: "data.content"
      }
    })], 1)], 1), _c('b-row', [_vm.type != 'pg' || _vm.type != 'pauli' ? _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [index + 1 == 1 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. E")]) : _vm._e(), _vm.type == 'multiple_choice' ? _c('b-form-checkbox', {
      staticClass: "custom-control-primary",
      attrs: {
        "value": "1"
      },
      model: {
        value: data.is_correct,
        callback: function callback($$v) {
          _vm.$set(data, "is_correct", $$v);
        },
        expression: "data.is_correct"
      }
    }, [_vm._v(" Kunci Jawaban ")]) : _vm._e(), _vm.type == 'benar_salah' ? _c('b-form-radio-group', {
      attrs: {
        "options": _vm.bsSoal,
        "name": "radios-stacked",
        "stacked": ""
      },
      model: {
        value: data.is_true_or_false,
        callback: function callback($$v) {
          _vm.$set(data, "is_true_or_false", $$v);
        },
        expression: "data.is_true_or_false"
      }
    }) : _vm._e()], 1) : _vm._e(), _vm.type != 'pg' ? _c('b-col', {
      attrs: {
        "md": "10"
      }
    }, [_c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Upload file format: jpg, png, pdf, doc, mp3, mp4 (opsional)")]), _c('b-form-file', {
      staticClass: "mb-2",
      attrs: {
        "id": data.id,
        "placeholder": "Pilih file untuk di upload atau unggah...",
        "drop-placeholder": "Drop file here...",
        "no-drop": "",
        "accept": ".jpg, .png, .pdf, .doc, .mp3, .mp4"
      },
      model: {
        value: data.opsi_file,
        callback: function callback($$v) {
          _vm.$set(data, "opsi_file", $$v);
        },
        expression: "data.opsi_file"
      }
    })], 1) : _vm._e(), _vm.type == 'pg' ? _c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Upload file format: jpg, png, pdf, doc, mp3, mp4 (opsional)")]), _c('b-form-file', {
      staticClass: "mb-2",
      attrs: {
        "id": data.id,
        "placeholder": "Pilih file untuk di upload atau unggah...",
        "drop-placeholder": "Drop file here...",
        "no-drop": "",
        "accept": ".jpg, .png, .pdf, .doc, .mp3, .mp4"
      },
      model: {
        value: data.opsi_file,
        callback: function callback($$v) {
          _vm.$set(data, "opsi_file", $$v);
        },
        expression: "data.opsi_file"
      }
    })], 1) : _vm._e()], 1)], 1)], 1)], 1);
  }), 0)], 1) : _vm._e(), _vm.type == 'pauli' ? _c('div', _vm._l(_vm.soals, function (data, index) {
    return _c('b-row', {
      key: data.id
    }, [_c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": 'Baris ' + (index + 1),
        "label-for": 'h-baris-' + (index + 1)
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": 'h-baris-' + data.id,
        "placeholder": "Isi Soal"
      },
      model: {
        value: data.content,
        callback: function callback($$v) {
          _vm.$set(data, "content", $$v);
        },
        expression: "data.content"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": 'Jawaban Baris ' + (index + 1),
        "label-for": 'h-baris-' + (index + 1)
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": 'h-baris-' + data.id,
        "placeholder": "Isi Jawaban"
      },
      model: {
        value: data.secret_key,
        callback: function callback($$v) {
          _vm.$set(data, "secret_key", $$v);
        },
        expression: "data.secret_key"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": 'Aksi Baris ' + (index + 1),
        "label-for": 'h-baris-' + (index + 1)
      }
    }, [data.id == null ? _c('b-button', {
      staticClass: "mr-1",
      attrs: {
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.TambahSub(data);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "SaveIcon"
      }
    })], 1) : _vm._e(), data.id != null ? _c('b-button', {
      staticClass: "mr-1",
      attrs: {
        "variant": "info"
      },
      on: {
        "click": function click($event) {
          return _vm.EditSub(data);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EditIcon"
      }
    })], 1) : _vm._e(), data.id != null ? _c('b-button', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteOpsi(data);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "TrashIcon"
      }
    })], 1) : _vm._e()], 1)], 1)], 1);
  }), 1) : _vm._e(), _c('b-button', {
    staticClass: "w-full mt-3",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }